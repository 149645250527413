.node-type-lab{
    .panel-pane{
        margin-bottom: 3rem;

    }

    .field-lab-details,
    .field-contact-info{
        padding-top: 4rem;
        border-top: 1px solid $wcm-border-gray;
    }
}