.node-type-institute{

    .panel-pane{
        &:not(.pane-bundle-wcm-page-item){
            margin-bottom: 5rem;
        }
    }

    .field-institute-pi{
        .field-label{
            display: inline-block;
            font-weight: bold;
        }
    }

    .qualtrics-form{
        height: 1000px;
    }

    .pane-node-field-institute-callouts{
        .paragraphs-item-wcm-pgraph-card-section{
            border-bottom: 1px solid $wcm-border-gray;
            margin-bottom: 5rem;
            padding-bottom: 5rem;
        }
    }

    .wcm-pgraph-side-callout--last{
        border-bottom: 1px solid $wcm-border-gray;
        padding-bottom: 5rem;
    }

    .pane-node-field-institute-generic-callout{
        border-bottom: 1px solid $wcm-border-gray;
        padding-bottom: 5rem;
    }
    
}
