
.wcm-pgraph-card{
    &__text{
        &__institute{
            margin-top: 1rem;
            color: $wcm-med-gray;
            &::before{
                content: url('../img/Image\ 82.png');
                padding-right: 1rem;
            }
        }
    }
}