// Institute detail page
.view-research-project-institute-ctas{
    border-bottom: 1px solid $wcm-border-gray;
    padding-bottom: 5rem;
    .view-content{
        @include make-row();
        .views-row{
            @include make-xs-column(12);
            @include make-md-column(6);
            margin-top: 2rem;

            a{
                color: $wcm-dark-gray;
                .wcm-pgraph-card{
                    &__image{
                        overflow: hidden;
                        img{
                            max-width: 100%;
                        }
                    }

                    &__text{
                        &::before{
                            position: relative;
                            top: -40px;
                            margin: 0 auto -40px;
                            content: '\e802';
                            width: 50px;
                            height: 50px;
                            color: $wcm-bright-orange;
                            background: $wcm-bg-gray;
                            border-radius: 50%;
                            font-size: 20px;
                            text-align: center;
                            @include fontello();
                            line-height: 50px;
                        }
                    }
        
                }

                &:hover{
                    .wcm-pgraph-card__text{
                        &::before{
                            color: $wcm-red;
                        }
                    }
                
                }
            }

        }
    }
}
