/**
 * Example Stub for creating WCMC Subthemes
 *
 */

// Bring in a few things from Bootstrap so we can use the grid, clearfix and vendor mixins (for transition, etc)
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins";
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/variables";
@import '../../../../../profiles/wcmc/themes/wcm_brand_base/fonts/fontello/css/fontello.css';

// Bring in the vars and custom mixins defined in the WCMC Bootstrap theme. Sets the WCMC Colors and Breakpoints
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/_variables";
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/_mixins";

/* =Site-specific styles
----------------------------------------------------------*/

@import "./default-select-style.scss";
@import "./mixins";
@import "navigation";
@import "header";
@import "footer";
@import "homepage";
@import "faculty";
@import "./views";
@import "./content_types";
@import "./paragraphs";
@import "./fields";
@import "./panes";
@import "./pages";