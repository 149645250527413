.paragraphs-item-metric{
    text-align: center;
    padding: 2rem;

    @include breakpoint($md){
        display: inline-block;
    }

    &:not(:last-child){
        border-bottom: 2px solid $wcm-border-gray;
        @include breakpoint($md){
            border-right: 2px solid $wcm-border-gray;
            border-bottom: none;
        }
    }

    .field-metric-number{
        color: $wcm-dark-orange;
        font-size: 4.8rem;
        font-family: $wcm-regular;
    }
    .field-metric-title{
        color: $wcm-med-gray;
        font-size: $font-size-h4;
        font-family: $headings-font-family;
    }
}