/*LEVEL 1*/
#primary-nav .level-1 {
    width: 16.5% !important;
}

/*LEVEL 2*/
#drawer-nav .level-2 {
   /* width: 19% !important; */
}
@include breakpoint($lg) {
    #drawer-nav .level-2 {
     /*  width: 20% !important;
      padding: 0px 15px; */
    }
} 
@include breakpoint($md) {
    #drawer-nav .level-2 {
        width: 15.5%;
    }
}

/*LEVEL 3*/
@include breakpoint($lg){
  #drawer-nav .level-3 {
    width: 100%;
  }
}

/*FOOTER*/
.block-menu-block .content {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
    -webkit-column-gap: 15px;
    -moz-column-gap: 15px;
    column-gap: 15px;
}
