/**
 * Example Stub for creating WCMC Subthemes
 *
 */
@import url(../../../../../profiles/wcmc/themes/wcm_brand_base/fonts/fontello/css/fontello.css);
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Site-specific styles
----------------------------------------------------------*/
/* =Default Select Elements
----------------------------------------------------------*/
.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid #dddddd;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px; }

/**
 * Mixin targeting the Biblio-related content
 */
/*LEVEL 1*/
#primary-nav .level-1 {
  width: 16.5% !important; }

/*LEVEL 2*/
#drawer-nav .level-2 {
  /* width: 19% !important; */ }

@media screen and (min-width: 1200px) {
  #drawer-nav .level-2 {
    /*  width: 20% !important;
      padding: 0px 15px; */ } }

@media screen and (min-width: 992px) {
  #drawer-nav .level-2 {
    width: 15.5%; } }

/*LEVEL 3*/
@media screen and (min-width: 1200px) {
  #drawer-nav .level-3 {
    width: 100%; } }

/*FOOTER*/
.block-menu-block .content {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
  -webkit-column-gap: 15px;
  -moz-column-gap: 15px;
  column-gap: 15px; }

/*LOGO STYLING*/
.brand__lockup img {
  height: 84px; }

/*FOOTER CONTACT US*/
@media screen and (min-width: 768px) {
  .footer-contact__contact-us a {
    padding: 20px 45px; } }

/*HOMEPAGE*/
/*COLUMNS*/
.thumbnail-controller {
  display: none; }

.left {
  text-align: left; }

.front .site-main .fieldable-panels-pane .external-link:after {
  content: '';
  display: block; }

/*ROW2*/
.row2-left {
  width: 31.9%;
  height: 260px;
  padding: 0% 2% 1% 2%;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  float: left;
  border: 1px solid #dddddd;
  box-shadow: 1px 1px 10px #888888;
  clear: left;
  margin: 2% 2% 0% 0.1%; }

.row2-center {
  width: 31.9%;
  height: 260px;
  padding: 0% 2% 1% 2%;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  float: left;
  border: 1px solid #dddddd;
  box-shadow: 1px 1px 10px #888888;
  margin: 2% 0% 0% 0%; }

.row2-right {
  width: 31.9%;
  height: 260px;
  padding: 0% 2% 1% 2%;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  float: left;
  border: 1px solid #dddddd;
  box-shadow: 1px 1px 10px #888888;
  margin: 2% 0.1% 0% 2%; }

/*ROW3*/
.row3-left {
  width: 31.9%;
  height: 100px;
  min-height: 100px;
  padding: 0% 2% 1% 2%;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  float: left;
  border: 1px solid #dddddd;
  box-shadow: 1px 1px 10px #888888;
  clear: left;
  margin: 2% 2% 0% 0.1%; }

.row3-center {
  width: 31.9%;
  height: 100px;
  min-height: 100px;
  padding: 0% 2% 1% 2%;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  float: left;
  border: 1px solid #dddddd;
  box-shadow: 1px 1px 10px #888888;
  position: static;
  margin: 2% 0% 0% 0%; }

.row3-right {
  width: 31.9%;
  height: 100px;
  min-height: 100px;
  padding: 0% 2% 1% 2%;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  float: left;
  border: 1px solid #dddddd;
  box-shadow: 1px 1px 10px #888888;
  margin: 2% 0.1% 0% 2%; }

@media (max-width: 991px) {
  /*PORTRAIT ROW2*/
  .row2-left {
    width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    margin: 0px;
    border: 0px 0px 1px 0px solid #dddddd;
    box-shadow: 0px 0px 0px 0px #fff; }
  .row2-center {
    width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    margin: 0px;
    border: 0px 0px 1px 0px solid #dddddd;
    box-shadow: 0px 0px 0px 0px #fff; }
  .row2-right {
    width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    margin: 0px;
    border: 0px 0px 1px 0px solid #dddddd;
    box-shadow: 0px 0px 0px 0px #fff; }
  /*PORTRAIT ROW3*/
  .row3-left {
    width: 100%;
    height: 35px;
    margin: 0px;
    border: 0px 0px 1px 0px solid #dddddd;
    box-shadow: 0px 0px 0px 0px #fff; }
  .row3-center {
    width: 100%;
    height: 35px;
    margin: 0px;
    border: 0px 0px 1px 0px solid #dddddd;
    box-shadow: 0px 0px 0px 0px #fff; }
  .row3-right {
    width: 100%;
    height: 35px;
    margin: 0px;
    border: 0px 0px 1px 0px solid #dddddd;
    box-shadow: 0px 0px 0px 0px #fff; } }

/*FACULTY VIEW STYLING*/
.facultyImg img {
  height: 250px;
  float: left;
  padding-left: 10px; }

.facultyName {
  margin: 10px;
  float: left; }

.facultyName a {
  font-weight: bold; }

.page-node-14 .pane-views-panes h2.pane-title {
  font-size: 16px;
  color: #fff;
  margin: 0;
  padding: 10px; }

.view-faculty .views-row {
  position: relative;
  height: 100px;
  float: left;
  width: 352px;
  line-height: 80px;
  border-bottom: 1px #ccc solid;
  border-right: 1px #ccc solid; }

.view-faculty {
  border-left: 1px #ccc solid; }

.panel-2col-stacked .panel-separator {
  margin: 0 0 1em 0; }

.select2-container--default {
  min-width: 300px !important; }

.views-exposed-form .views-exposed-widget .form-submit {
  margin-top: 2.4em; }

.gridcol {
  padding: 10px; }

.profile-list-item .profile-list-item__pro-title ul li {
  margin: 0; }

.view-news .view-content .view-teaser {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 2rem; }
  .view-news .view-content .view-teaser .teaser-image img {
    float: none; }
    @media screen and (min-width: 992px) {
      .view-news .view-content .view-teaser .teaser-image img {
        float: right; } }

.view-biblio-views-institute {
  /* =Publications Index
    ----------------------------------------------------------*/ }
  .view-biblio-views-institute .views-row {
    margin: 0 0 10px; }
    .view-biblio-views-institute .views-row [class^="biblio-title"] {
      font-weight: bold; }
  .view-biblio-views-institute h3 {
    color: #000000;
    font-weight: bold;
    background-color: #e1e1e1;
    border: 1px solid #ccc;
    padding: 10px 15px 5px;
    margin: 20px 0; }

.view-grant-list .view-grouping-header {
  margin-top: 2rem; }
  .view-grant-list .view-grouping-header h2 {
    margin-top: 0; }

.view-grant-list h3 {
  text-transform: uppercase; }

.view-grant-list .views-row {
  border-top: 1px solid #dddddd;
  margin-bottom: 2rem; }
  .view-grant-list .views-row .views-field .views-label {
    display: inline-block;
    width: 25%;
    font-weight: bold; }
  .view-grant-list .views-row .views-field .field-content {
    display: inline-block;
    max-width: 74%; }

.view-associated-members-vivo {
  margin-top: 4.5rem;
  padding: 5rem 0;
  border-top: 1px solid #dddddd; }
  .view-associated-members-vivo .view-header {
    font-family: "1898Sans-Bold", sans-serif;
    text-transform: uppercase;
    color: #666666; }
  .view-associated-members-vivo .view-content {
    margin-top: 2rem; }
    @media screen and (min-width: 768px) {
      .view-associated-members-vivo .view-content {
        column-count: 2; } }

.view-research-project-institute-ctas {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 5rem; }
  .view-research-project-institute-ctas .view-content {
    margin-left: -20px;
    margin-right: -20px; }
    .view-research-project-institute-ctas .view-content:before, .view-research-project-institute-ctas .view-content:after {
      content: " ";
      display: table; }
    .view-research-project-institute-ctas .view-content:after {
      clear: both; }
    .view-research-project-institute-ctas .view-content .views-row {
      position: relative;
      float: left;
      width: 100%;
      min-height: 1px;
      padding-left: 20px;
      padding-right: 20px;
      position: relative;
      min-height: 1px;
      padding-left: 20px;
      padding-right: 20px;
      margin-top: 2rem; }
      @media (min-width: 992px) {
        .view-research-project-institute-ctas .view-content .views-row {
          float: left;
          width: 50%; } }
      .view-research-project-institute-ctas .view-content .views-row a {
        color: #555555; }
        .view-research-project-institute-ctas .view-content .views-row a .wcm-pgraph-card__image {
          overflow: hidden; }
          .view-research-project-institute-ctas .view-content .views-row a .wcm-pgraph-card__image img {
            max-width: 100%; }
        .view-research-project-institute-ctas .view-content .views-row a .wcm-pgraph-card__text::before {
          position: relative;
          top: -40px;
          margin: 0 auto -40px;
          content: '\e802';
          width: 50px;
          height: 50px;
          color: #e7751d;
          background: #f7f7f7;
          border-radius: 50%;
          font-size: 20px;
          text-align: center;
          font-family: "fontello";
          font-style: normal;
          font-weight: normal;
          speak: none;
          display: inline-block;
          text-decoration: inherit;
          text-align: center;
          /* opacity: .8; */
          /* For safety - reset parent styles, that can break glyph codes*/
          font-variant: normal;
          text-transform: none;
          /* fix buttons height, for twitter bootstrap */
          line-height: 1em;
          /* Animation center compensation - margins should be symmetric */
          /* remove if not needed */
          /* you can be more comfortable with increased icons size */
          /* font-size: 120%; */
          /* Font smoothing. That was taken from TWBS */
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          /* Uncomment for 3D effect */
          /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
          line-height: 50px; }
        .view-research-project-institute-ctas .view-content .views-row a:hover .wcm-pgraph-card__text::before {
          color: #b31b1b; }

.view-news-sidebar {
  border: 1px solid #dddddd;
  padding: 1rem 2rem;
  margin-bottom: 2rem; }
  .view-news-sidebar .view-header {
    text-transform: uppercase; }
    .view-news-sidebar .view-header a {
      color: #666666;
      font-size: 15px; }
  .view-news-sidebar .view-content ul {
    padding-left: 1rem; }
    .view-news-sidebar .view-content ul .views-row {
      margin-top: 1rem; }

/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/*** Directory ***/
.view-person-list:before, .view-lab-members-list:before, .view-person-list:after, .view-lab-members-list:after {
  content: " ";
  display: table; }

.view-person-list:after, .view-lab-members-list:after {
  clear: both; }

.view-person-list .view-content, .view-lab-members-list .view-content {
  margin-left: -20px;
  margin-right: -20px; }
  @media screen and (min-width: 768px) {
    .view-person-list .view-content, .view-lab-members-list .view-content {
      display: flex;
      flex-wrap: wrap; }
      .view-person-list .view-content:before, .view-lab-members-list .view-content:before {
        display: block; } }
  .view-person-list .view-content .person-list-item, .view-lab-members-list .view-content .person-list-item {
    margin: 0 0 30px; }
    @media screen and (min-width: 768px) {
      .view-person-list .view-content .person-list-item, .view-lab-members-list .view-content .person-list-item {
        display: flex;
        flex-direction: column; } }
    .view-person-list .view-content .person-list-item .person-display-name, .view-lab-members-list .view-content .person-list-item .person-display-name {
      font-weight: 700;
      margin-bottom: 5px;
      font-size: 15px; }
      .view-person-list .view-content .person-list-item .person-display-name .person-name-suffix:before, .view-lab-members-list .view-content .person-list-item .person-display-name .person-name-suffix:before {
        content: ', ';
        position: relative; }
      .view-person-list .view-content .person-list-item .person-display-name .person-name-suffix:empty:before, .view-lab-members-list .view-content .person-list-item .person-display-name .person-name-suffix:empty:before {
        content: ''; }
    .view-person-list .view-content .person-list-item .person-headshot, .view-lab-members-list .view-content .person-list-item .person-headshot {
      margin-bottom: 5px;
      border-top: 8px solid #b31b1b;
      overflow: hidden;
      position: relative;
      transition: all 0.3s ease 0s; }
      .view-person-list .view-content .person-list-item .person-headshot img, .view-lab-members-list .view-content .person-list-item .person-headshot img {
        width: 100%;
        transition: all 0.3s ease 0s; }
      .view-person-list .view-content .person-list-item .person-headshot .view-profile-details, .view-lab-members-list .view-content .person-list-item .person-headshot .view-profile-details {
        cursor: pointer; }
        .view-person-list .view-content .person-list-item .person-headshot .view-profile-details .view-details-btn, .view-lab-members-list .view-content .person-list-item .person-headshot .view-profile-details .view-details-btn {
          position: absolute;
          background-color: #b31b1b;
          color: #fff;
          bottom: 0;
          right: 0;
          padding: 3px 10px;
          font-size: 15px; }
          .view-person-list .view-content .person-list-item .person-headshot .view-profile-details .view-details-btn:after, .view-lab-members-list .view-content .person-list-item .person-headshot .view-profile-details .view-details-btn:after {
            content: "\e80a";
            padding-left: 5px;
            font-family: "fontello";
            font-style: normal;
            font-weight: normal;
            speak: none;
            display: inline-block;
            text-decoration: inherit;
            text-align: center;
            /* opacity: .8; */
            /* For safety - reset parent styles, that can break glyph codes*/
            font-variant: normal;
            text-transform: none;
            /* fix buttons height, for twitter bootstrap */
            line-height: 1em;
            /* Animation center compensation - margins should be symmetric */
            /* remove if not needed */
            /* you can be more comfortable with increased icons size */
            /* font-size: 120%; */
            /* Font smoothing. That was taken from TWBS */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            /* Uncomment for 3D effect */
            /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }
        .view-person-list .view-content .person-list-item .person-headshot .view-profile-details:hover img, .view-lab-members-list .view-content .person-list-item .person-headshot .view-profile-details:hover img {
          transform: scale(1.1); }
        .view-person-list .view-content .person-list-item .person-headshot .view-profile-details:hover .view-details-btn, .view-lab-members-list .view-content .person-list-item .person-headshot .view-profile-details:hover .view-details-btn {
          background-color: #cf4520; }
    .view-person-list .view-content .person-list-item.is-hover .person-headshot, .view-lab-members-list .view-content .person-list-item.is-hover .person-headshot {
      border-top: 8px solid #cf4520; }
      .view-person-list .view-content .person-list-item.is-hover .person-headshot img, .view-lab-members-list .view-content .person-list-item.is-hover .person-headshot img {
        transform: scale(1.1); }
    .view-person-list .view-content .person-list-item.is-hover .view-details-btn, .view-lab-members-list .view-content .person-list-item.is-hover .view-details-btn {
      background-color: #cf4520 !important; }
      .view-person-list .view-content .person-list-item.is-hover .view-details-btn:after, .view-lab-members-list .view-content .person-list-item.is-hover .view-details-btn:after {
        transform: rotate(180deg);
        padding-right: 5px;
        padding-left: 0 !important; }
    @media screen and (min-width: 768px) {
      .view-person-list .view-content .person-list-item.is-hover:after, .view-lab-members-list .view-content .person-list-item.is-hover:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 20px 20px 20px;
        border-color: transparent transparent #f7f7f7 transparent;
        position: absolute;
        bottom: -30px;
        left: 50%;
        margin-left: -20px; } }
    .view-person-list .view-content .person-list-item .person-details-wrapper, .view-lab-members-list .view-content .person-list-item .person-details-wrapper {
      display: none; }
      .view-person-list .view-content .person-list-item .person-details-wrapper .field-person-details, .view-lab-members-list .view-content .person-list-item .person-details-wrapper .field-person-details {
        background-color: #f7f7f7;
        padding: 15px;
        margin-top: 20px;
        position: relative; }
        .view-person-list .view-content .person-list-item .person-details-wrapper .field-person-details:before, .view-lab-members-list .view-content .person-list-item .person-details-wrapper .field-person-details:before {
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 20px 20px 20px;
          border-color: transparent transparent #f7f7f7 transparent;
          position: absolute;
          top: -15px;
          left: 50%;
          margin-left: -20px; }
    .view-person-list .view-content .person-list-item .person-title ul, .view-lab-members-list .view-content .person-list-item .person-title ul {
      margin: 0;
      padding: 0; }
      .view-person-list .view-content .person-list-item .person-title ul li, .view-lab-members-list .view-content .person-list-item .person-title ul li {
        list-style-type: none;
        margin: 0;
        margin-bottom: 5px; }

.view-person-list .bio-row-container, .view-lab-members-list .bio-row-container {
  display: none; }
  .view-person-list .bio-row-container .bio-row-content, .view-lab-members-list .bio-row-container .bio-row-content {
    background-color: #f7f7f7;
    padding: 15px;
    margin: 0px 0px 30px;
    position: relative; }
  .view-person-list .bio-row-container.is-hidden, .view-lab-members-list .bio-row-container.is-hidden {
    display: none; }

.pane-person-list-panel-pane-2 .pane-title {
  margin-bottom: 20px;
  font-size: 30px; }

.pane-person-list-panel-pane-2 .view-person-list .view-content, .pane-person-list-panel-pane-2 .view-lab-members-list .view-content {
  display: block;
  margin-left: 0;
  margin-right: 0; }

.pane-person-list-panel-pane-2 .view-person-list .person-list-item, .pane-person-list-panel-pane-2 .view-lab-members-list .person-list-item {
  display: block;
  font-size: inherit; }
  @media screen and (min-width: 768px) {
    .pane-person-list-panel-pane-2 .view-person-list .person-list-item .person-headshot, .pane-person-list-panel-pane-2 .view-lab-members-list .person-list-item .person-headshot {
      float: left;
      margin-right: 30px; } }
  .pane-person-list-panel-pane-2 .view-person-list .person-list-item .person-display-name, .pane-person-list-panel-pane-2 .view-lab-members-list .person-list-item .person-display-name {
    font-family: "1898Sans-Bold", sans-serif;
    font-size: 20px;
    color: #b31b1b; }
  .pane-person-list-panel-pane-2 .view-person-list .person-list-item .views-field-field-wcm-person-department, .pane-person-list-panel-pane-2 .view-lab-members-list .person-list-item .views-field-field-wcm-person-department {
    margin-bottom: 10px; }
  .pane-person-list-panel-pane-2 .view-person-list .person-list-item .views-field-field-wcm-person-clinical-url, .pane-person-list-panel-pane-2 .view-lab-members-list .person-list-item .views-field-field-wcm-person-clinical-url, .pane-person-list-panel-pane-2 .view-person-list .person-list-item .views-field-field-wcm-person-research-url, .pane-person-list-panel-pane-2 .view-lab-members-list .person-list-item .views-field-field-wcm-person-research-url {
    margin-bottom: 5px; }
  .pane-person-list-panel-pane-2 .view-person-list .person-list-item .views-field-field-project-locations .views-label, .pane-person-list-panel-pane-2 .view-lab-members-list .person-list-item .views-field-field-project-locations .views-label {
    color: #b31b1b;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 5px; }
  .pane-person-list-panel-pane-2 .view-person-list .person-list-item .views-field-view .view-projects, .pane-person-list-panel-pane-2 .view-lab-members-list .person-list-item .views-field-view .view-projects {
    margin-top: 10px; }
    .pane-person-list-panel-pane-2 .view-person-list .person-list-item .views-field-view .view-projects .featured-projects-heading, .pane-person-list-panel-pane-2 .view-lab-members-list .person-list-item .views-field-view .view-projects .featured-projects-heading {
      color: #b31b1b;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 5px; }
  .pane-person-list-panel-pane-2 .view-person-list .person-list-item .views-field-view .view-content, .pane-person-list-panel-pane-2 .view-lab-members-list .person-list-item .views-field-view .view-content {
    margin-left: 0;
    margin-right: 0; }

.pane-person-list-panel-pane-3 {
  background-color: #555555;
  max-width: 300px;
  margin: 30px auto 0; }
  @media screen and (min-width: 768px) {
    .pane-person-list-panel-pane-3 {
      max-width: none; } }
  .pane-person-list-panel-pane-3 .view-person-list, .pane-person-list-panel-pane-3 .view-lab-members-list {
    position: relative; }
    .pane-person-list-panel-pane-3 .view-person-list .view-content, .pane-person-list-panel-pane-3 .view-lab-members-list .view-content {
      display: block;
      margin-left: 0;
      margin-right: 0; }
    .pane-person-list-panel-pane-3 .view-person-list .person-list-item, .pane-person-list-panel-pane-3 .view-lab-members-list .person-list-item {
      flex-direction: inherit;
      font-size: inherit;
      margin-bottom: 0; }
      .pane-person-list-panel-pane-3 .view-person-list .person-list-item .person-headshot, .pane-person-list-panel-pane-3 .view-lab-members-list .person-list-item .person-headshot {
        border: none; }
        @media screen and (min-width: 768px) {
          .pane-person-list-panel-pane-3 .view-person-list .person-list-item .person-headshot, .pane-person-list-panel-pane-3 .view-lab-members-list .person-list-item .person-headshot {
            float: left;
            width: 30%;
            padding-right: 30px;
            margin-bottom: 0; } }
      .pane-person-list-panel-pane-3 .view-person-list .person-list-item .person-display-name, .pane-person-list-panel-pane-3 .view-lab-members-list .person-list-item .person-display-name {
        font-family: "1898Sans-Bold", sans-serif;
        font-size: 20px; }
      .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-view .view-content, .pane-person-list-panel-pane-3 .view-lab-members-list .person-list-item .views-field-view .view-content {
        margin-left: 0;
        margin-right: 0; }
      .pane-person-list-panel-pane-3 .view-person-list .person-list-item .project-locations div, .pane-person-list-panel-pane-3 .view-lab-members-list .person-list-item .project-locations div {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 5px; }
      .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-1, .pane-person-list-panel-pane-3 .view-lab-members-list .person-list-item .views-field-nothing-1 {
        position: relative;
        padding: 20px; }
        @media screen and (min-width: 768px) {
          .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-1, .pane-person-list-panel-pane-3 .view-lab-members-list .person-list-item .views-field-nothing-1 {
            padding: 0 20px 0 30px;
            width: 70%;
            float: left; }
            .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-1 .person-details, .pane-person-list-panel-pane-3 .view-lab-members-list .person-list-item .views-field-nothing-1 .person-details {
              position: relative;
              top: 50%;
              -webkit-transform: translateY(-50%);
              /* Ch <36, Saf 5.1+, iOS, An =<4.4.4 */
              -ms-transform: translateY(-50%);
              /* IE 9 */
              transform: translateY(-50%);
              /* IE 10, Fx 16+, Op 12.1+ */
              position: absolute;
              width: 100%;
              padding-right: 40px; } }
        .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-1 .view-projects, .pane-person-list-panel-pane-3 .view-lab-members-list .person-list-item .views-field-nothing-1 .view-projects {
          margin-top: 20px; }
          .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-1 .view-projects .featured-projects-heading, .pane-person-list-panel-pane-3 .view-lab-members-list .person-list-item .views-field-nothing-1 .view-projects .featured-projects-heading {
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 5px; }
          .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-1 .view-projects .project-details, .pane-person-list-panel-pane-3 .view-lab-members-list .person-list-item .views-field-nothing-1 .view-projects .project-details {
            display: inline;
            margin: 0; }
            .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-1 .view-projects .project-details:after, .pane-person-list-panel-pane-3 .view-lab-members-list .person-list-item .views-field-nothing-1 .view-projects .project-details:after {
              content: ', ';
              position: relative;
              margin-left: -4px; }
            .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-1 .view-projects .project-details:last-child:after, .pane-person-list-panel-pane-3 .view-lab-members-list .person-list-item .views-field-nothing-1 .view-projects .project-details:last-child:after {
              content: ''; }
            .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-1 .view-projects .project-details a, .pane-person-list-panel-pane-3 .view-lab-members-list .person-list-item .views-field-nothing-1 .view-projects .project-details a {
              color: #ffc72c; }
      @media screen and (min-width: 768px) {
        .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-2, .pane-person-list-panel-pane-3 .view-lab-members-list .person-list-item .views-field-nothing-2 {
          position: absolute;
          bottom: 0;
          right: 0; } }
      @media screen and (min-width: 768px) and (max-width: 991px) {
        .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-2, .pane-person-list-panel-pane-3 .view-lab-members-list .person-list-item .views-field-nothing-2 {
          bottom: -45px; } }
      .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-2 a, .pane-person-list-panel-pane-3 .view-lab-members-list .person-list-item .views-field-nothing-2 a {
        position: relative;
        background: #cf4520;
        padding: 10px 40px;
        border: none;
        margin: 5px 0 0;
        display: block;
        text-align: center; }
        @media screen and (min-width: 768px) {
          .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-2 a, .pane-person-list-panel-pane-3 .view-lab-members-list .person-list-item .views-field-nothing-2 a {
            margin: 0;
            float: left; }
            .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-2 a:first-child:not(:last-child):before, .pane-person-list-panel-pane-3 .view-lab-members-list .person-list-item .views-field-nothing-2 a:first-child:not(:last-child):before {
              content: '';
              position: absolute;
              right: 0;
              height: 70%;
              top: 15%;
              border: 0.5px solid #fff;
              opacity: 0.5; } }
        .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-2 a:hover, .pane-person-list-panel-pane-3 .view-lab-members-list .person-list-item .views-field-nothing-2 a:hover {
          background: #b31b1b; }

.view-lab-members-list .view-header {
  font-family: "1898Sans-Bold", sans-serif;
  font-size: 15px;
  text-transform: uppercase;
  color: #666666; }

.view-lab-project-list .view-content .views-row {
  margin-bottom: 4rem; }

.view-research-project-list .view-content .views-row {
  overflow: hidden;
  margin-top: 3rem; }
  .view-research-project-list .view-content .views-row:not(:last-of-type) {
    padding-bottom: 5rem;
    border-bottom: 1px solid #dddddd; }
  .view-research-project-list .view-content .views-row .views-field-field-featured-image {
    margin-top: 2rem; }
    .view-research-project-list .view-content .views-row .views-field-field-featured-image img {
      width: 100%; }
    @media screen and (min-width: 992px) {
      .view-research-project-list .view-content .views-row .views-field-field-featured-image {
        float: left;
        margin-right: 2rem;
        max-width: 33%; } }
  .view-research-project-list .view-content .views-row .views-field-nothing {
    overflow: hidden; }
    .view-research-project-list .view-content .views-row .views-field-nothing .project-title {
      font-size: 20px;
      margin-bottom: 1rem; }
    .view-research-project-list .view-content .views-row .views-field-nothing .project-details {
      margin-bottom: 1rem; }
    .view-research-project-list .view-content .views-row .views-field-nothing .project-institute::before {
      content: url("../img/Image 82.png");
      padding-right: 1rem; }
    .view-research-project-list .view-content .views-row .views-field-nothing .project-institute a {
      color: #666666; }

.node-type-research-project .field-project-active {
  border-top: 2px solid #dddddd;
  border-bottom: 2px solid #dddddd;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-top: 6rem; }

.node-type-research-project .field-project-investigators,
.node-type-research-project .field-project-grant,
.node-type-research-project .field-project-institute,
.node-type-research-project .field-project-last-updated {
  margin-top: 2rem; }
  .node-type-research-project .field-project-investigators .field-label,
  .node-type-research-project .field-project-grant .field-label,
  .node-type-research-project .field-project-institute .field-label,
  .node-type-research-project .field-project-last-updated .field-label {
    width: 25%;
    font-weight: bold; }
    @media screen and (min-width: 768px) {
      .node-type-research-project .field-project-investigators .field-label,
      .node-type-research-project .field-project-grant .field-label,
      .node-type-research-project .field-project-institute .field-label,
      .node-type-research-project .field-project-last-updated .field-label {
        float: left; } }
  .node-type-research-project .field-project-investigators .field-content,
  .node-type-research-project .field-project-grant .field-content,
  .node-type-research-project .field-project-institute .field-content,
  .node-type-research-project .field-project-last-updated .field-content {
    overflow: hidden; }

.node-type-research-project .field-project-investigators {
  margin-top: 5rem; }

.node-type-research-project .field-metrics {
  padding: 1rem 0;
  border-top: 2px solid #dddddd;
  border-bottom: 2px solid #dddddd; }

.node-type-research-project .field-project-details {
  margin-top: 5rem; }

.node-type-research-project .field-project-video {
  margin-top: 4rem; }

.node-type-institute .panel-pane:not(.pane-bundle-wcm-page-item) {
  margin-bottom: 5rem; }

.node-type-institute .field-institute-pi .field-label {
  display: inline-block;
  font-weight: bold; }

.node-type-institute .qualtrics-form {
  height: 1000px; }

.node-type-institute .pane-node-field-institute-callouts .paragraphs-item-wcm-pgraph-card-section {
  border-bottom: 1px solid #dddddd;
  margin-bottom: 5rem;
  padding-bottom: 5rem; }

.node-type-institute .wcm-pgraph-side-callout--last {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 5rem; }

.node-type-institute .pane-node-field-institute-generic-callout {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 5rem; }

.node-type-lab .panel-pane {
  margin-bottom: 3rem; }

.node-type-lab .field-lab-details,
.node-type-lab .field-contact-info {
  padding-top: 4rem;
  border-top: 1px solid #dddddd; }

.paragraphs-item-metric {
  text-align: center;
  padding: 2rem; }
  @media screen and (min-width: 992px) {
    .paragraphs-item-metric {
      display: inline-block; } }
  .paragraphs-item-metric:not(:last-child) {
    border-bottom: 2px solid #dddddd; }
    @media screen and (min-width: 992px) {
      .paragraphs-item-metric:not(:last-child) {
        border-right: 2px solid #dddddd;
        border-bottom: none; } }
  .paragraphs-item-metric .field-metric-number {
    color: #cf4520;
    font-size: 4.8rem;
    font-family: "1898Sans-Regular", sans-serif; }
  .paragraphs-item-metric .field-metric-title {
    color: #666666;
    font-size: 18px;
    font-family: "1898Sans-Bold", sans-serif; }

.paragraphs-item-contact {
  text-align: center; }
  .paragraphs-item-contact .field-contact-details {
    font-weight: bold; }
  .paragraphs-item-contact .group-phone-details {
    margin-top: 1rem; }
    .paragraphs-item-contact .group-phone-details > div {
      display: inline-block; }
      .paragraphs-item-contact .group-phone-details > div:not(:last-child) {
        border-right: 2px solid #dddddd;
        margin-right: 2rem;
        padding-right: 2rem; }
    .paragraphs-item-contact .group-phone-details .field-label {
      display: inline; }

.wcm-pgraph-card__text__institute {
  margin-top: 1rem;
  color: #666666; }
  .wcm-pgraph-card__text__institute::before {
    content: url("../img/Image 82.png");
    padding-right: 1rem; }

.field-institute-generic-callout {
  overflow: hidden; }

.research-landing .panel-pane:not(.pane-bundle-wcm-page-item) {
  margin-bottom: 5rem; }

.research-landing .pane-upcoming-events {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 5rem; }

.research-landing .wcm-pgraph-side-callout--last {
  border-bottom: 1px solid #dddddd;
  margin-bottom: 5rem;
  padding-bottom: 5rem; }
