/*HOMEPAGE*/

/*COLUMNS*/
$three-col-margin-left: 2% 2% 0% .10%;
$three-col-margin-center: 2% 0% 0% 0%;
$three-col-margin-right: 2% .10% 0% 2%;
$three-col-width: 31.9%;

.thumbnail-controller{
  display: none;
}
.left {
  text-align: left; }

.front .site-main .fieldable-panels-pane .external-link:after {
 content: '';
 display: block;
}

/*ROW2*/

  @mixin row2-dim{
    width: $three-col-width;
    height: 260px;
    padding: 0% 2% 1% 2%;
    display: inline-block;      
    text-align: center; 
    overflow: hidden;
    float: left;
    border: 1px solid $wcm-border-gray;
    box-shadow: 1px 1px 10px #888888;
      }

      .row2-left{
        @include row2-dim;
        clear: left;  
        margin: $three-col-margin-left;
        }

      .row2-center{
        @include row2-dim;
        margin: $three-col-margin-center;
        }

      .row2-right{
        @include row2-dim;  
        margin: $three-col-margin-right;
        }


/*ROW3*/
  @mixin row3-dim{
    width: $three-col-width;
    height: 100px;
    min-height: 100px;
    padding: 0% 2% 1% 2%;
    display: inline-block;      
    text-align: center; 
    overflow: hidden;
    float: left;     
    border: 1px solid $wcm-border-gray;
    box-shadow: 1px 1px 10px #888888;
      }

      .row3-left{
        @include row3-dim;
        clear: left;
        margin: $three-col-margin-left;
        }

      .row3-center{
        @include row3-dim;
        position: static; 
        margin: $three-col-margin-center;
        }

      .row3-right{
        @include row3-dim;  
        margin: $three-col-margin-right;
        }

@media (max-width : 991px) {
    /*PORTRAIT ROW2*/
    @mixin row2-dim-phone{
      width: 100%;
      height: 100%;  
      min-height: 100%;
      max-height: 100%;
      margin: 0px;
      border: 0px 0px 1px 0px solid $wcm-border-gray;
      box-shadow: 0px 0px 0px 0px $wcm-white;
    } 
    
        .row2-left{
          @include row2-dim-phone;
        }

        .row2-center{
          @include row2-dim-phone;
        }

        .row2-right{
          @include row2-dim-phone;      
        }
    
    /*PORTRAIT ROW3*/    
    @mixin row3-dim-phone{
      width: 100%;
      height: 35px;    
      margin: 0px;
      border: 0px 0px 1px 0px solid $wcm-border-gray;
      box-shadow: 0px 0px 0px 0px $wcm-white;  
        }
    
        .row3-left{
          @include row3-dim-phone;
        }

        .row3-center{
          @include row3-dim-phone;
        }

        .row3-right{
          @include row3-dim-phone;      
        }
}