.view-associated-members-vivo{
    margin-top: 4.5rem;
    padding: 5rem 0;
    border-top: 1px solid $wcm-border-gray;
        
    .view-header{
        font-family: $headings-font-family;
        text-transform: uppercase;
        color: $wcm-med-gray;
    }
    .view-content{
        margin-top: 2rem;
        @include breakpoint($sm){
            column-count: 2;
        }
    }
}
