// Institute Sidebar
.view-news-sidebar{
    border: 1px solid $wcm-border-gray;
    padding: 1rem 2rem;
    margin-bottom: 2rem;

    .view-header{
        text-transform: uppercase;
        a{
            color: $wcm-med-gray;
            font-size: $font-size-base;
        }
    }
    .view-content{
        ul{
            padding-left: 1rem;

            .views-row{
                margin-top: 1rem;
            }
        }
    }
    
}
