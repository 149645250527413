/**
 * Mixin targeting the Biblio-related content
 */
 @mixin biblio-views {
    /* =Publications Index
    ----------------------------------------------------------*/    
    .views-row {
        margin: 0 0 10px;

        [class^="biblio-title"]{
            font-weight: bold;
        }
    }
    
    h3 {
        color: #000000;
        font-weight: bold;
        background-color: #e1e1e1;
        border: 1px solid #ccc;
        padding: 10px 15px 5px;
        margin: 20px 0;
    }
}
