// Research Project
.node-type-research-project{

    .field-project-active{
        border-top: 2px solid $wcm-border-gray;
        border-bottom: 2px solid $wcm-border-gray;
        text-align: center;
        padding-top: 2rem;
        padding-bottom: 2rem;
        margin-top: 6rem;
    }

    .field-project-investigators,
    .field-project-grant,
    .field-project-institute,
    .field-project-last-updated{
        margin-top: 2rem;
        .field-label{
            width: 25%;
            font-weight: bold;
            @include breakpoint($sm){
                float: left;
            }
        }
        .field-content{
            overflow: hidden;
        }
    }
    .field-project-investigators{
        margin-top: 5rem;
    }

    .field-metrics{
        padding: 1rem 0;
        border-top: 2px solid $wcm-border-gray;
        border-bottom: 2px solid $wcm-border-gray;
    }

    .field-project-details{
        margin-top: 5rem;
    }

    .field-project-video{
        margin-top: 4rem;
    }
}