
// Research Landing Page
.research-landing{
    .panel-pane{
        &:not(.pane-bundle-wcm-page-item){
            margin-bottom: 5rem;
        }
    }

    .pane-upcoming-events{
        border-bottom: 1px solid $wcm-border-gray;
        padding-bottom: 5rem;
    }

    .wcm-pgraph-side-callout--last{
        border-bottom: 1px solid $wcm-border-gray;
        margin-bottom: 5rem;
        padding-bottom: 5rem;
    }
}