.view-news{
    .view-content{
        .view-teaser{
            border-bottom: 1px solid $wcm-border-gray;
            padding-bottom: 2rem;

            .teaser-image{
                img{
                    float: none;
                    @include breakpoint($md){
                        float: right;
                    }
                }
            }
        }
    }
}
