@import "../../../../../../profiles/wcmc/modules/custom/wcm_person/css/wcm_person.scss";

// Lab pages Members
.view-lab-members-list{
    @extend .view-person-list;

    .view-header{
        font-family: $wcm-bold;
        font-size: $font-size-base;
        text-transform: uppercase;
        color: $wcm-med-gray;
    }
}
