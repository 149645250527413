// Research Projects Listing
.view-research-project-list{
    
    .view-content{
        .views-row{
            overflow: hidden;
            margin-top: 3rem;
            &:not(:last-of-type){
                padding-bottom: 5rem;
                border-bottom: 1px solid $wcm-border-gray;
            }

            .views-field-field-featured-image{
                margin-top: 2rem;

                img{
                    width: 100%;
                }

                @include breakpoint($md){
                    float: left;
                    margin-right: 2rem;
                    max-width: 33%;
                }
            }
            .views-field-nothing{
                overflow: hidden;

                .project-title{
                    font-size: $font-size-h3;
                    margin-bottom: 1rem;
                }

                .project-details{
                    margin-bottom: 1rem;
                }

                .project-institute{
                    &::before{
                        content: url('../img/Image 82.png');
                        padding-right: 1rem;
                    }
                    a{
                        color: $wcm-med-gray;
                    }
                }
            }
        }        
    }
}