.view-grant-list {
    .view-grouping-header {
        margin-top: 2rem;

        h2 {
            margin-top: 0;
        }
    }

    h3 {
        text-transform: uppercase;
    }

    .views-row {
        border-top: 1px solid $wcm-border-gray;
        margin-bottom: 2rem;

        .views-field {
            .views-label {
                display: inline-block;
                width: 25%;
                font-weight: bold;
            }

            .field-content {
                display: inline-block;
                max-width: 74%;
            }
        }
    }
}
