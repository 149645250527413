/*FACULTY VIEW STYLING*/
.facultyImg img {
    height: 250px;
    float: left;
    padding-left: 10px;
}

.facultyName {
    margin: 10px;
    float: left;
}

.facultyName a {
    font-weight: bold;
}

.page-node-14 .pane-views-panes h2.pane-title {
    font-size: 16px;
    color: $wcm-white;
    margin: 0;
    padding: 10px;
}

.view-faculty .views-row {
    position: relative;
    height: 100px;
    float: left;
    width: 352px;
    line-height: 80px;
    border-bottom: 1px #ccc solid;
    border-right: 1px #ccc solid;
}

.view-faculty {
    border-left: 1px #ccc solid;
}

.panel-2col-stacked .panel-separator {
    margin: 0 0 1em 0;
}

.select2-container--default {
    min-width: 300px !important;
}

.views-exposed-form .views-exposed-widget .form-submit {
    margin-top: 2.4em;
}

.gridcol {
    padding: 10px;
}

.profile-list-item .profile-list-item__pro-title ul li {
    margin: 0;
}
