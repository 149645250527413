// Bring in a few things from Bootstrap so we can use the grid, clearfix and vendor mixins (for transition, etc)
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/variables";
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins/_clearfix";

// Bring in the vars and custom mixins defined in the WCMC Bootstrap theme. Sets the WCMC Colors and Breakpoints
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/_variables";
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/_mixins";

/*** Directory ***/
.view-person-list {
  @include clearfix();

  .view-content {
    margin-left: -20px;
    margin-right: -20px;

    @include breakpoint($sm) {
      display: flex;
      flex-wrap: wrap;
      &:before {
        display: block;
      }
    }

    .person-list-item {

      @include breakpoint($sm) {
        display: flex;
        flex-direction: column;
      }

      margin: 0 0 30px;
      // font-size: 13px;

      .person-display-name {
        font-weight: 700;
        margin-bottom: 5px;
        font-size: 15px;

        .person-name-suffix {
          &:before {
            content: ', ';
            position: relative;
          }
          &:empty {
            &:before {
              content: '';
            }
          }
        }
      }

      .person-headshot {
        margin-bottom: 5px;
        border-top: 8px solid $wcm-red;
        overflow: hidden;
        position: relative;
        transition: all 0.3s ease 0s;

        img {
          width: 100%;
          transition: all 0.3s ease 0s;
        }

        .view-profile-details {
          cursor: pointer;

          .view-details-btn {
            position: absolute;
            background-color: $wcm-red;
            color: $wcm-white;
            bottom: 0;
            right: 0;
            padding: 3px 10px;
            font-size: 15px;

            &:after {
              content: "\e80a";
              padding-left: 5px;
              @include fontello();
            }
          }

          &:hover {
            img {
              transform: scale(1.1);
            }

            .view-details-btn {
              background-color: $wcm-dark-orange;
            }
          }
        }
      }

      &.is-hover {
        .person-headshot {
          border-top: 8px solid $wcm-dark-orange;
          img {
            transform: scale(1.1);
          }
        }
        .view-details-btn {
          background-color: $wcm-dark-orange !important;
          &:after {
            transform: rotate(180deg);
            padding-right: 5px;
            padding-left: 0 !important;
          }
        }

        &:after {
          @include breakpoint($sm) {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 20px 20px 20px;
            border-color: transparent transparent $wcm-bg-gray transparent;
            position: absolute;
            bottom: -30px;
            left: 50%;
            margin-left: -20px;
          }
        }
      }

      .person-details-wrapper {
        display: none;
        .field-person-details {
          background-color: $wcm-bg-gray;
          padding: 15px;
          margin-top: 20px;
          position: relative;

          &:before {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 20px 20px 20px;
            border-color: transparent transparent $wcm-bg-gray transparent;
            position: absolute;
            top: -15px;
            left: 50%;
            margin-left: -20px;
          }
        }
      }

      .person-title {
        ul {
          margin: 0;
          padding: 0;

          li {
            list-style-type: none;
            margin: 0;
            margin-bottom: 5px;
          }
        }
      }
    }
  }


  .bio-row-container {
    display: none;

    .bio-row-content {
      background-color: $wcm-bg-gray;
      // font-size: 13px;
      padding: 15px;
      margin: 0px 0px 30px;
      position: relative;
    }

    &.is-hidden {
      display: none;
    }
  }

}

.pane-person-list-panel-pane-2 {
  .pane-title {
    margin-bottom: 20px;
    font-size: 30px;
  }
  .view-person-list {
    .view-content {
      display: block;
      margin-left: 0;
      margin-right: 0;
    }

    .person-list-item {
      display: block;
      font-size: inherit;

      .person-headshot {
        @include breakpoint($sm) {
          float: left;
          margin-right: 30px;
        }
      }

      .person-display-name {
        font-family: $wcm-bold;
        font-size: 20px;
        color: $wcm-red;
      }
      .views-field-field-wcm-person-department {
        margin-bottom: 10px;
      }
      .views-field-field-wcm-person-clinical-url, .views-field-field-wcm-person-research-url {
        margin-bottom: 5px;
      }

      .views-field-field-project-locations {
        .views-label {
          color: $wcm-red;
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 5px;
        }
      }
      .views-field-view {

        .view-projects {
          margin-top: 10px;

          .featured-projects-heading {
            color: $wcm-red;
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 5px;
          }
        }
        .view-content {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
}

.pane-person-list-panel-pane-3 {
  background-color: $wcm-dark-gray;
  max-width: 300px;
  margin: 30px auto 0;
  @include breakpoint($sm) {
    max-width: none;
  }

  .view-person-list {
    position: relative;

    .view-content {
      display: block;
      margin-left: 0;
      margin-right: 0;
    }

    .person-list-item {
      // display: block;
      flex-direction: inherit;
      font-size: inherit;
      margin-bottom: 0;

      .person-headshot {
        border: none;
        @include breakpoint($sm) {
          float: left;
          width: 30%;
          padding-right: 30px;
          margin-bottom: 0;
        }
      }

      .person-display-name {
        font-family: $wcm-bold;
        font-size: 20px;
      }
      .views-field-view {
        .view-content {
          margin-left: 0;
          margin-right: 0;
        }
      }

      .project-locations {
        div {
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 5px;
        }
      }

      .views-field-nothing-1 {
        position: relative;
        padding: 20px;
        @include breakpoint($sm) {
          padding: 0 20px 0 30px;
          width: 70%;
          float: left;

          .person-details {
            @include v-center();
            position: absolute;
            width: 100%;
            padding-right: 40px;
          }
        }

        .view-projects {
          margin-top: 20px;

          .featured-projects-heading {
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 5px;
          }

          .project-details {
            display: inline;
            margin: 0;

            &:after {
              content: ', ';
              position: relative;
              margin-left: -4px;
            }
            &:last-child {
              &:after {
                content: '';
              }
            }

            a {
              color: $wcm-yellow;
            }
          }
        }
      }

      .views-field-nothing-2 {
        @include breakpoint($sm) {
          position: absolute;
          bottom: 0;
          right: 0;
        }
        @include breakpoint($sm-only) {
          bottom: -45px;
        }

        a {
          position: relative;
          background: $wcm-dark-orange;
          padding: 10px 40px;
          // display: inline;
          border: none;
          margin: 5px 0 0;
          display: block;
          text-align: center;
          @include breakpoint($sm) {
            margin: 0;
            float: left;

            &:first-child:not(:last-child) {
              &:before {
                content: '';
                position: absolute;
                right: 0;
                height: 70%;
                top: 15%;
                border: 0.5px solid #fff;
                opacity: 0.5;
              }
            }
          }

          &:hover {
            background: $wcm-red;
          }
        }
      }
    }
  }
}
